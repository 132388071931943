<template>
	<div>
		<transition name="slide-bottom-transition">
			<app-bottom-bar v-show="showToolBar">
				<div class="flex justify-between items-center h-full">
					<p class="hidden md:block text-sm text-gray-800 dark:text-neutral-200">{{ $t('text.questions') }}</p>
					<wrapper grow right>
						<btn @click="resultasticStore.showBookingModal=true" color="primary" outlined shadow large :label="$t('action.contact_us')"/>
						<btn :to="`${$config.public.resultasticUrl}/create`" color="primary" shadow large :label="`${$t('action.get_started_now')}!`"/>
					</wrapper>
				</div>
			</app-bottom-bar>
		</transition>

		<nuxt-page/>

		<client-only>
			<ajax-form-modal v-model="resultasticStore.showBookingModal"
							 :form-options="{action: '/v1/contact', method: 'POST', data: contactSubmitData, reset: true}"
							 :modal-options="{title: $t('action.contact_us'), subtitle: 'Du hast noch Fragen? Dann kontaktiere uns und wir werden uns zeitnah bei Dir melden.'}"
							 @success="handleSubmittedContactMessage"
							 :submit-text="$t('action.send')">

				<contact-message-form-inputs :data="contactData"
											 without-captcha
											 force-contact
											 message-placeholder="Solltest du Fragen oder ein anderes Anliegen zu resultastic und SPOFERAN haben, so teile uns dies hier mit..."/>

			</ajax-form-modal>
		</client-only>
	</div>
</template>

<script setup lang="ts">
import {useResultasticInfoPage} from "~/composables/useResultasticInfoPage";
import {Images} from "@spoferan/spoferan-ts-core";

const {$web, $image, $analytics} = useNuxtApp();
const {t} = useI18n();
const {resultasticStore} = useResultasticInfoPage();

const showToolBar = ref(false);
const contactData = ref({});

const contactSubmitData = computed(() => {
	const data = {...contactData.value};
	data.message = 'Ich interessiere mich für resultastic: ' + (data.message ? data.message : 'Keine Nachricht verfasst.');

	return data;
});

/**
 * Intersection listener to show the action toolbar when the listener element is visible or above the viewport.
 */
const handleScroll = () => {
	showToolBar.value = window.scrollY > 500;
}

const handleSubmittedContactMessage = () => {
	$analytics.trackEvent('contact', {location: 'about_resultastic'
	});
};

onMounted(() => {
	document.addEventListener('scroll', handleScroll);
});
onBeforeUnmount(() => {
	document.removeEventListener('scroll', handleScroll);
})

useMeta({
	title: t('view.resultastic.title'),
	description: t('view.resultastic.description'),
	image: $image.format(Images.resultastic.logo, {width: 1200, height: 630, fit: 'contain'})
})
</script>
